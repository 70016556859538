<template>
  <!-- compliance -->
  <div class="compliance">
    <!-- <div class="title">
      <h4 class="breadtitle">安全事件</h4>
    </div> -->
    <navi-gation style="font-size: 16px" />
    <div>
      <el-tabs v-model="activeName" type="card" class="tabs">
        <el-tab-pane
          :label="$t('DeviceManage.Securityinc.Longtermoffline')"
          name="first"
        >
          <el-form
            size="small"
            :model="json"
            label-width="200px"
            ref="json"
            class="demo-ruleForm"
          >
            <el-form-item label="">
              <el-radio-group
                v-model="json.use"
                class="adio"
                @change="setRadio"
              >
                <el-radio v-model="json.use" label="1">{{
                  $t("public.Enable")
                }}</el-radio>
                <el-radio v-model="json.use" label="0">{{
                  $t("public.Forbidden")
                }}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              :label="$t('DeviceManage.Securityinc.Offlineduration') + '：'"
              required
            >
              <el-input
                style="width: 180px; margin-right: 10px"
                v-model="json.offlineTime"
                :disabled="disabled"
                @input="updateOfflineTime"
                maxlength="4"
              ></el-input>
              <el-select
                :disabled="disabled"
                style="width: 100px"
                v-model="json.timeUnit"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in unitOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <!-- <span style="margin-left: 10px">{{$t('DeviceManage.Securityinc.Day')}}</span> -->
            </el-form-item>
            <el-form-item
              :label="$t('DeviceManage.Securityinc.eventLevel') + '：'"
            >
              <el-radio-group v-model="json.level">
                <el-radio :label="3" :disabled="disabled">{{
                  $t("DeviceManage.Securityinc.high")
                }}</el-radio>
                <el-radio :label="2" :disabled="disabled">{{
                  $t("DeviceManage.Securityinc.centre")
                }}</el-radio>
                <el-radio :label="1" :disabled="disabled">{{
                  $t("DeviceManage.Securityinc.low")
                }}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              :label="$t('DeviceManage.Securityinc.ResponseAction') + '：'"
              required
            >
              <el-checkbox-group
                v-model="json.content"
                @change="handleCheckedCitiesChange"
              >
                <el-checkbox label="0" :disabled="disabled">{{
                  $t("DeviceManage.Securityinc.Missingtags")
                }}</el-checkbox>
                <el-checkbox label="1" :disabled="disabled">{{
                  $t("DeviceManage.Securityinc.Cleardata")
                }}</el-checkbox>
                <el-checkbox label="2" :disabled="disabled">{{
                  $t("DeviceManage.Securityinc.Forcederegis")
                }}</el-checkbox>
                <el-checkbox label="3" :disabled="disabled">{{
                  $t("DeviceManage.Securityinc.Deactivatedevice")
                }}</el-checkbox>
                <el-checkbox label="4" :disabled="disabled">{{
                  $t("DeviceManage.Securityinc.RestoreFactory")
                }}</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item>
              <!-- <el-button type="primary" @click="handleStartDateChange" size="mini" -->
              <el-button
                type="primary"
                @click="submitForm('json')"
                size="mini"
                >{{ $t("public.Save") }}</el-button
              >
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </div>

    <dialog-info
      :dialogInfo="visibaelFlag"
      @handleClose="handleClose"
      @determine="determine"
      :titleInfo="titleInfo"
      :width="'240px'"
    />
  </div>
</template>

<script>
const cityOptions = [
  { value: 1, loss: "标记丢失" },
  { value: 2, deleteData: "清除数据" },
  { value: 3, logout: "强制注销" },
  { value: 4, stopEqu: "停用设备" },
  { value: 5, recovery: "恢复出厂" },
];
import dialogInfo from "@/components/promptMessage/index";
import naviGation from "@/components/hearder/index";
export default {
  components: { dialogInfo, naviGation },
  name: "compliance",

  data() {
    return {
      visibaelFlag: false, //提示消息默认--关闭
      titleInfo: "", // 提示框的提示语
      checkAll: false,
      cities: cityOptions,
      isIndeterminate: true,
      checked: false,
      btnstatus: true,
      activeName: "first",
      disabled: true,
      json: {
        use: 0,
        timeUnit: "day", //v6.3.2版本优化离线时长配置，支持选择天、小时、分钟 默认选择天，也可以选择小时、分钟
        level: 3, //v6.3.2版本新增 支持配置 事件级别 默认选择高，可选择其他级别
        offlineTime: "",
        content: [],
      },
      Successdialog: false, //控制弹出
      titleInfo: "",
      isDisabled: false,
      //v6.3.2版本优化离线时长配置，支持选择天、小时、分钟 默认选择天，也可以选择小时、分钟
      unitOptions: [
        {
          value: "day",
          label: "天",
        },
        {
          value: "hour",
          label: "小时",
        },
        {
          value: "minute",
          label: "分钟",
        },
      ],
      // unitValue: "day",
    };
  },

  methods: {
    //通过 @input 事件监听器来监听输入框的值变化
    updateOfflineTime(event) {
      console.log(event, "event");
      // 使用字符串的 replace 方法移除非数字字符
      this.json.offlineTime = event.replace(/[^\d]/g, "");
    },
    // 查看分析接口
    async gethandle() {
      var params;
      params = {};
      const res = await this.$axios.post(
        "/httpServe/longOffline/selectOne",
        params,
        true
      );
      console.log(res, "请求");

      this.json.use = res.data.use + "";
      this.setRadio(res.data.use);
      this.json.offlineTime = res.data.offlineTime;
      this.json.level = res.data.level || 3; // 确保level不会是null或undefined
      this.json.timeUnit = res.data.timeUnit || 'day'; // 确保timeUnit不会是null或undefined
      this.json.content = res.data.content;
    },
    // 复选框
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cities.length;
    },
    // 判断禁用
    setRadio(val) {
      if (val == 0) {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
    },
    // 提示框
    async handleStartDateChange() {
      console.log(this.json.content);
      console.log(this.json.use, "length", this.json.offlineTime);
      if (this.json.content.length == 0) {
        if (this.json.use == 1) {
          this.$message({
            message: this.$t("DeviceManage.Securityinc.Atleastoneitem"),
            type: "error",
            offset: 100,
          });
          return false;
        }
      }
      //启用时
      if (this.json.use == 1) {
        const regex = /^[1-9][0-9]{0,3}$/;
        console.log(regex.test(this.json.offlineTime), "this.json.offlineTime");
        if (regex.test(this.json.offlineTime)) {
          var params = this.json;
          const res = await this.$axios.post(
            "/httpServe/longOffline/update",
            params,
            true
          );
          this.$message({
            message: this.$t("public.SuccessfullySaved"),
            type: "success",
            offset: 100,
          });
        } else {
          this.$message({
            message: this.$t("DeviceManage.Securityinc.Offlinedura"),
            type: "error",
            offset: 100,
          });
          return false;
        }
      } else {
        //禁用时
        var params = this.json;
        const res = await this.$axios.post(
          "/httpServe/longOffline/update",
          params,
          true
        );
        this.$message({
          message: this.$t("public.SuccessfullySaved"),
          type: "success",
          offset: 100,
        });
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.handleStartDateChange();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleClose() {
      this.visibaelFlag = false; //提示消息  关闭
    },
    //确定回调事件
    determine() {
      this.visibaelFlag = false; //提示消息  关闭
    },
    // // 关闭当前页面
    // sendMsg() {
    //   this.Successdialog = false;
    //   window.clearInterval(this.time);
    //   setTimeout(() => {
    //     this.Sencond = 5;
    //   }, 1000);
    // }
  },

  mounted() {
    this.gethandle();
  },
};
</script>

<style lang="scss" scoped>
* {
  font-family: Microsoft YaHei;
  font-size: 12px;
}
// ::v-deep .el-form-item {
//   margin-bottom: 17px !important;
// }
// ::v-deep .el-form-item__error{
//   padding-top:1px !important;
// }
.el-form-item {
  margin-bottom: 17px !important;
}
.editbox {
  padding-top: 15px;
  padding-bottom: 25px;
  margin: 0 10px;
  margin-bottom: 0;
}
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #000 !important;
}
.el-input--small .el-input__inner {
  height: 28px;
}
.star {
  color: red;
}
.leftspace {
  display: flex;
  width: 100%;
  // margin-top: 8px;
  .el-input {
    width: 200px;
    margin-right: 5px;
  }
}
.inpbox {
  margin-left: 100px;
  padding-top: 8px;
}
.group {
  padding-top: 8px;
}
.spans {
  width: 72px;
}
.tabs {
  height: 350px;
  border: 1px solid #dddd;
  margin-left: 10px;
  margin-top: 30px;
  margin: 10px;
  margin-bottom: 0;
  clear: both;
  border-radius: 5px;
}
.btn {
  margin-left: 20px;
  width: 84px;
  color: #fff;
  border: none;
  background: #4787de;
  cursor: pointer;
  border-radius: 3px;
}
.el-checkbox__label {
  line-height: 13px;
}
.mock {
  line-height: 24px;
}
.breadtitle {
  margin-left: 10px;
  border-bottom: 1px solid #cdd1dd;
  height: 40px;
  line-height: 40px;
  color: #6d7a82;
  font-size: 16px;
}
.el-tabs__item.is-active {
  color: #000;
}
.btnBox {
  margin-top: 20px;
  margin-left: 162px;
  height: 30px;
  padding-bottom: 25px;
}
// 保存确认提示框
#success {
  .el-dialog {
    border-radius: 6px;
    .el-dialog__header {
      padding: 6px;
      background: #1e89e5;
      font-size: 18px;
      text-decoration: none;
      border-radius: 6px 6px 0 0;
      .el-dialog__title {
        color: #fff;
        font-weight: bold;
        font-size: 14px;
      }
      .el-dialog__headerbtn {
        top: 10px !important;
      }
      .el-dialog__headerbtn .el-dialog__close {
        color: #fff !important;
      }
    }
    .el-dialog__body {
      padding: 30px 10px !important;
      text-align: center !important;
    }
    .el-dialog__footer {
      padding: 5px 20px !important;
      background-color: #f5f5f5;
      border-top: 1px solid #ddd;
      border-radius: 0 0 6px 6px;
    }
  }
}
.el-button--primary {
  background-color: #1e89e5 !important;
  border-color: #1e89e5 !important;
}
.el-message-box  {
  width: 240px;
}
.el-message-box__header  {
  background: #1e89e5;
}
.el-message-box__headerbtn  {
  top: 9px;
}
.el-icon-close:before  {
  content: "\e6db";
  color: white;
}
</style>
<style scoped>
.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  border-bottom-color: #fff;
  border-top: 2px solid #1d89e3;
}
.el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: 1px solid #e4e7ed;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  border-top: none;
}
.el-tabs__item.is-active {
  color: #000;
}
.el-tabs__item {
  padding: 0 12px;
  height: 36px;
  box-sizing: border-box;
  line-height: 36px;
  display: inline-block;
  list-style: none;
  font-size: 12px;
  font-weight: 500;
  color: #999999;
  position: relative;
}
.el-form-item__label {
  padding: 0 0px 0 0 !important;
}
.el-tabs__header {
  margin: 0 0 10px !important;
}
</style>
